import Vue from 'vue'
import VueRouter from 'vue-router'

Vue.use(VueRouter);
const routes = [{
    path: '/',
    name: 'main',
    // beforeEnter: guest,
    component: ()=>import ('../components/main.vue')
  },
    {
  
      path: '/about',
      name: 'About-Us',
      // beforeEnter: guest,
      component: ()=>import ('../components/about.vue')
  },
]

const router = new VueRouter({
    mode: "history",
    base: process.env.BASE_URL,
    routes,
    scrollBehavior() {
        document.getElementById('app').scrollIntoView({ behavior: 'smooth' });
    }
    // scrollBehavior(to, from, savedPosition){
    //     if(savedPosition) {
    //         return savedPosition
    //     }
    //         else{
    //             return{x: 0, y: 0}
    //         }
            
    //     }
  });

export default router
