<template>
  <v-app id="app">    
    <v-main role="main">
              <router-view></router-view>
    </v-main>
   
  </v-app>
</template>

<script>

export default {
  name: "App",
}
</script>

<style scoped>

</style>